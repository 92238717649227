import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import InputComponent from "../../../components/Input/InputComponent";
import { UseFetch } from "../../../hooks/useFetch";
import { PostApi } from "../../../services/Api";
import LoadingComponent from "../../../components/Loading/LoadingComponent";
import { StoreType } from "../../../types/Stores";
import { ProductType } from "../../../types/Products";
import { SimpleSearchParams } from "../../../hooks/SearchParams";

const CreateSale = ({ updateSales }: { updateSales: () => void }) => {
  const SearchParams = SimpleSearchParams();
  let storeId = SearchParams.get("store");
  let [manufacturingValue, setManufacturing] = useState<number>(0);
  let [price, setPrice] = useState<number>(0);
  let [quantity, setQuantity] = useState<number>(1);
  let [acquisition, setAcquisition] = useState<number>(0);
  let [products, setProducts] = useState<ProductType[]>([]);
  let [Stores, setStores] = useState<StoreType[]>([]);
  let [storeGroupId, setStoreGroupId] = useState("");
  let [isShopee, setIsShopee] = useState(true);
  let [originalManufacturingValue, setOriginalManufacturingValue] = useState(0);
  let [selectedStoreId, setSelectedStoreId] = useState<string | undefined>(
    storeId ? `${storeId}` : undefined
  );
  let [selectedModelId, setSelelectedModelId] = useState<string | undefined>();
  let [CreateLoading, setCreateLoading] = useState<boolean>(false);
  let {
    data: ProductsData,
    error: ProductsError,
    loading: ProductsLoading,
    update: updateProducts,
  } = UseFetch<ProductType[]>("/products", {
    params: { groupId: storeGroupId },
  });
  let {
    data: StoresData,
    error: StoresError,
    loading: StoresLoading,
  } = UseFetch<StoreType[]>("/stores/all");
  let hasCommission =
    StoresData?.find((store) => store.storeId === selectedStoreId)?.user?.group
      ?.hasCommission || false;
  useEffect(() => {
    if (ProductsError || ProductsLoading) return;
    if (ProductsData === null) return;
    setProducts(ProductsData);
  }, [ProductsLoading, ProductsData, ProductsError]);
  useEffect(updateProducts, [storeGroupId]);
  useEffect(() => {
    if (StoresError || StoresLoading) return;
    if (StoresData === null) return;
    setStores(StoresData);
  }, [StoresLoading, StoresData, StoresError]);

  const handleModelSelected = (element: ChangeEvent<HTMLSelectElement>) => {
    let { target } = element;
    let product = products.find(
      (product) => product.productId === target.value
    );
    if (product) {
      setAcquisition(product.acquisitionValue);
      setPrice(product.standardAmount);
      setManufacturing(product.manufacturingValue);
      setOriginalManufacturingValue(product.originalManufacturingValue || 0);
    }
    setSelelectedModelId(target.value);
  };
  const handleStoreSelected = (element: ChangeEvent<HTMLSelectElement>) => {
    let { target } = element;
    let store = Stores.find((store) => store.storeId === target.value);

    if (
      !store?.user?.group?.useDefaultProducts &&
      store?.user?.group?.groupId
    ) {
      setStoreGroupId(store.user.group.groupId);
    } else {
      setStoreGroupId("");
    }
    // updateProducts();
    setSelectedStoreId(target.value);
  };

  const handleSubmit = async (element: FormEvent<HTMLFormElement>) => {
    element.preventDefault();
    if (CreateLoading) return;
    setCreateLoading(true);
    if (!price) return;
    if (!acquisition) return;
    if (!quantity) return;
    let currentDate = new Date().toLocaleDateString("pt-BR").split("/");
    let FormatedDate = `${currentDate[2]}-${currentDate[1]}-${currentDate[0]}`;
    PostApi("/sales", {
      storeId: selectedStoreId,
      productId: selectedModelId,
      saleDate: FormatedDate,
      acquisitionValue: acquisition * 100,
      amount: price * 100,
      quantity,
      isShopee,
      manufacturingValue: hasCommission ? manufacturingValue * 100 : 0,
      originalManufacturingValue: originalManufacturingValue * 100,
    })
      .then(() => {
        updateSales();
      })
      .finally(() => {
        setCreateLoading(false);
        clearData();
      });
  };
  const storeProfit = () => {
    let totalAmont = price * quantity;
    let fixedTax = 4 * quantity;
    let taxedAmont = totalAmont * 0.8 - fixedTax;
    let cost = acquisition * quantity;
    if (isShopee) return taxedAmont - cost;
    return totalAmont - cost;
  };
  const clearData = () => {
    setSelelectedModelId("");
    setAcquisition(0);
    setPrice(0);
    setQuantity(1);
    setOriginalManufacturingValue(0);
  };
  return (
    <section id="CreateSale">
      <header>
        <h1>Adicionar venda</h1>
        <h3>Preencha as informações abaixo para adicionar a venda realizada</h3>
      </header>
      <LoadingComponent isLoading={CreateLoading} />
      <form onSubmit={handleSubmit}>
        <div id="store">
          <label htmlFor="storeSelect">Loja</label>
          <select
            name="storeSelected"
            id="storeSelect"
            onChange={handleStoreSelected}
            value={selectedStoreId}
            required
            disabled={storeId ? true : false}
          >
            <option value="" selected disabled>
              Selecione a loja
            </option>
            {Stores &&
              Stores.map((store) => {
                if (store.isFactory || store.disabled) return null;
                return (
                  <option key={store.storeId} value={store.storeId}>
                    {store.name}
                  </option>
                );
              })}
          </select>
        </div>
        <div id="model">
          <label htmlFor="modelSelect">Modelo</label>
          <select
            name="modelSelected"
            id="modelSelect"
            onChange={handleModelSelected}
            value={selectedModelId}
            required
          >
            <option value="" selected disabled>
              Selecione o modelo
            </option>
            {products &&
              products.map((product) => {
                return (
                  <option key={product.productId} value={product.productId}>
                    {product.name}
                  </option>
                );
              })}
          </select>
        </div>
        <div id="manufacturing" className={hasCommission ? "comission" : ""}>
          <div>
            <InputComponent
              id="originalManufacturingValue"
              label="Custo de fabricação:"
              value={originalManufacturingValue}
              type="number"
              placeholder="Valor"
              step="0.01"
              min="0.01"
              onChange={(element: FormEvent<HTMLInputElement>) => {
                let { value } = element.currentTarget;
                setOriginalManufacturingValue(Number(value));
              }}
              required
            />
          </div>
          {hasCommission && (
            <div>
              <InputComponent
                id="manufacturing"
                label="Valor de venda fábrica:"
                value={manufacturingValue}
                type="number"
                placeholder="Valor"
                step="0.01"
                min="0"
                onChange={(element: FormEvent<HTMLInputElement>) => {
                  let { value } = element.currentTarget;
                  console.log(value);

                  if (value.length !== 1 && value.startsWith("0")) {
                    setManufacturing(Number(value.replace("0", "")));
                  }
                  setManufacturing(Number(value));
                }}
                required
              />
            </div>
          )}
        </div>
        <div id="acquisition" className={hasCommission ? "comission" : ""}>
          <InputComponent
            id="acquisitions"
            label="Custo de aquisição loja:"
            value={acquisition}
            type="number"
            placeholder="Valor"
            step="0.01"
            min="0.01"
            onChange={(element: FormEvent<HTMLInputElement>) => {
              let { value } = element.currentTarget;
              setAcquisition(Number(value));
            }}
            required
          />
          {hasCommission && (
            <InputComponent
              id="lucrorepresenter"
              label="Lucro representante:"
              disabled
              style={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor:
                  (acquisition - manufacturingValue) * quantity > 0
                    ? "#058900"
                    : "#d12953",
              }}
              value={((acquisition - manufacturingValue) * quantity).toFixed(2)}
            />
          )}
        </div>
        <div id="lucrofabrica">
          <span>
            <InputComponent
              id="lucrofabrica"
              label="Lucro fábrica:"
              disabled
              style={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor:
                  ((hasCommission ? manufacturingValue : acquisition) -
                    originalManufacturingValue) *
                    quantity >
                  0
                    ? "#058900"
                    : "#d12953",
              }}
              value={(
                ((hasCommission ? manufacturingValue : acquisition) -
                  originalManufacturingValue) *
                quantity
              ).toFixed(2)}
            />
          </span>
        </div>
        <div id="price">
          <div>
            <InputComponent
              id="price"
              label="Preço de venda loja:"
              value={price}
              type="number"
              placeholder="Valor"
              step="0.01"
              min="0.01"
              onChange={(element: FormEvent<HTMLInputElement>) => {
                let { value } = element.currentTarget;
                setPrice(Number(value));
              }}
              required={true}
            />
          </div>
          <div id="isshopee">
            Venda da shopee?
            <button onClick={() => setIsShopee(!isShopee)} type="button">
              {isShopee ? "Sim" : "Não"}
            </button>
          </div>
        </div>
        <div id="lucroloja">
          <span>
            <InputComponent
              id="lucroloja"
              label="Lucro Loja:"
              disabled
              style={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: Number(storeProfit()) > 0 ? "#058900" : "#d12953",
              }}
              value={Number(storeProfit().toFixed(2))}
            />
          </span>
        </div>
        <div id="quantity">
          <InputComponent
            id="quantity"
            label="Quantidade"
            value={quantity}
            type="number"
            placeholder="Quantidade"
            onChange={(element: FormEvent<HTMLInputElement>) => {
              let { value } = element.currentTarget;
              setQuantity(Number(value));
            }}
            required
          />
        </div>
        <div id="data">
          <InputComponent
            id="data"
            label="Data"
            type="text"
            disabled
            value={new Date().toLocaleDateString("pt-BR")}
            required={true}
          />
        </div>
        <div id="buttons">
          <button type="reset" className="second" onClick={clearData}>
            Limpar
          </button>
          <button type="submit">Criar</button>
        </div>
      </form>
    </section>
  );
};

export default CreateSale;
